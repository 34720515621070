import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { events } from '@/events';
import { useStorageListener } from '@/hooks/storage';
import { RouterOutput, trpc } from '@/trpc';

import { Auth } from './auth';

type Authenticated = {
  status: 'authenticated';
  data: NonNullable<RouterOutput['session']['current']>;
  token: string;
};

type Unauthenticated = {
  status: 'unauthenticated';
};

type Loading = {
  status: 'loading';
};

type Context = Authenticated | Unauthenticated | Loading;

export interface UseAuthOptions<R extends boolean> {
  required: R;
  onUnauthenticated?: () => void;
}

const AuthContext = createContext<Context>(undefined!);

export const useAuth = <R extends boolean>(options?: UseAuthOptions<R>) => {
  const data = useContext(AuthContext);
  if (!data) {
    throw new Error('useAuth must be used inside an AuthProvider');
  }

  const { required, onUnauthenticated } = options ?? {};
  const requiredAndNotLoading = required && data.status === 'unauthenticated';

  useEffect(() => {
    if (requiredAndNotLoading) {
      if (onUnauthenticated) onUnauthenticated();
      else Auth.signOut();
    }
  }, [requiredAndNotLoading, onUnauthenticated]);

  if (requiredAndNotLoading) {
    return { data, status: 'loading' } as const;
  }

  return data;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const utils = trpc.useContext();
  const [token, setToken] = useState<string>(null!);

  const { isLoading, data } = trpc.session.current.useQuery(void 0, {
    suspense: false,
    onSuccess: (session) => {
      if (session) {
        events.send('signedIn', {
          user: {
            id: session.userID,
            email: session.email,
            name: session.name,
          },
          company: {
            id: session.companyID,
          },
          role: session.role,
        });
      }
    },
    onError: (error) => {
      if (error?.data?.code === 'UNAUTHORIZED') {
        // TODO: refresh
        Auth.signOut();
      }
    },
  });

  useStorageListener('token', (token) => {
    if (!token) {
      Auth.signOut();
      return;
    }

    setToken(token!);
    utils.invalidate();
  });

  useEffect(() => {
    Auth.parseToken();
    setToken(Auth.getToken());
  }, []);

  const value = useMemo(
    () =>
      (isLoading
        ? { status: 'loading' }
        : data
        ? { status: 'authenticated', data, token }
        : { status: 'unauthenticated' }) satisfies Context,
    [isLoading, data, token]
  );

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? null : children}
    </AuthContext.Provider>
  );
};
