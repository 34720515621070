import format from 'date-fns/format';
import { enUS, es, ptBR } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import LocalStorageBackend, {
  LocalStorageBackendOptions,
} from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';
import { makeZodI18nMap } from 'zod-i18n-map';

import * as z from '@exact-magic/validation';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const dateFnLocales = { en: enUS, pt: ptBR, es };

i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init<ChainedBackendOptions>({
    compatibilityJSON: 'v3',
    fallbackLng: 'pt',
    supportedLngs: ['pt', 'en', 'es'],
    defaultNS: 'app',
    ns: ['app', 'zod'],
    returnNull: false,
    react: {
      transSupportBasicHtmlNodes: true,
    },
    interpolation: {
      formatSeparator: ',',
      escapeValue: false,
      format: function (value, formatting, locale) {
        if (formatting === 'date') {
          return format(new Date(value), 'Pp', {
            locale: dateFnLocales[locale as keyof typeof dateFnLocales],
          });
        }
        return value.toString();
      },
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000,
        } satisfies LocalStorageBackendOptions,
        {
          loadPath: '/locales/{{ns}}/{{lng}}.json',
        } satisfies HttpBackendOptions,
      ],
    },
  });

z.setErrorMap(makeZodI18nMap({ ns: ['common', 'zod'] }));

export * from './t';
export { Trans, useTranslation } from 'react-i18next';
export { i18n };
