import { events } from '@/events';
import type { UserLogin, UserRegister } from '@magicwrite/schemas/user';

export * as Auth from './auth';

function decode(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var payload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(payload);
}

export const getToken = () => localStorage.getItem('token') || '';

export const setToken = (token: string) => localStorage.setItem('token', token);

export type Session = {
  iat: number;
  type: 'user';
  properties: {
    userID: string;
    companyID: string;
    role: 'admin' | 'user';
    email: string;
    name: string;
  };
};

export const getSession = (): Session => {
  const token = getToken();
  return token ? decode(token) : null;
};

export const login = async (params: UserLogin) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/auth/basic/authorize`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(params),
    }
  );

  if (response.redirected) {
    window.location.href = response.url;
  }

  return response.redirected;
};

export const register = async (params: UserRegister) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/auth/basic/register`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(params),
    }
  );

  if (response.redirected) {
    window.location.href = response.url;
  }

  return response.redirected;
};

export const signOut = async () => {
  events.send('signedOut', {
    user: {
      id: undefined,
      email: undefined,
      name: undefined,
    },
  });

  setToken('');
  window.location.href = '/auth/login';
};

export const parseToken = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  if (token) {
    setToken(token);
    params.delete('token');
  }
};
