import * as z from '@exact-magic/validation';
import { User } from './user.base';

export const SendRecoveryLink = User.pick({ email: true });
export type SendRecoveryLink = z.input<typeof SendRecoveryLink>;

export const VerifyRecoveryLink = z.object({ token: z.id('tkn') });
export type VerifyRecoveryLink = z.input<typeof VerifyRecoveryLink>;

export const RecoverPassword = z.object({
  token: z.id('tkn'),
  password: z.password(),
});
export type RecoverPassword = z.infer<typeof RecoverPassword>;
