import { Dot, DotProps, cx } from '@exact-magic/ui';
import { Actor } from './types';

export interface IndicatorProps extends DotProps {
  actor: Actor;
  children?: React.ReactNode;
  className?: string;
}

export const Indicator = ({
  actor,
  children,
  className,
  ...props
}: IndicatorProps) => {
  return (
    <div className="relative">
      {children}
      <div
        className={cx(
          'absolute bottom-0 right-0 rounded-full bg-white p-0.5',
          className
        )}
      >
        <Dot color="success" {...props} />
      </div>
    </div>
  );
};
