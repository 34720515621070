import type { Type } from '@magicwrite/core/company';

import * as z from '@exact-magic/validation';
import { audit, implement, timestamps } from '../schema';
import * as User from '../user';

export const ID = z.id('co');
export type ID = z.infer<typeof ID>;

export const Company = implement<Type>().with({
  companyID: ID,
  userID: User.ID,
  name: z.string().min(3),
  site: z.string().optional(),
  description: z.string().optional(),
  ...audit,
  ...timestamps,
});
