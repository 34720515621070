import { Suspense } from 'react';
import {
  Avatar,
  Indicator,
  PageSettings,
} from '@magicwrite/web/agents/components';
import { trpc } from '@magicwrite/web/trpc';
import { Outlet } from 'react-router-dom';

import { cx } from '@exact-magic/ui';
import { Layout } from './layout';
import { useUI } from './ui';
import { useWidgetParams } from './utils';

export function App() {
  const { companyID, groupID } = useWidgetParams();
  const { ui, setUI } = useUI();

  const [group] = trpc.agentGroups.public.get.useSuspenseQuery({
    companyID,
    groupID,
  });

  return (
    <PageSettings
      group={group}
      className="h-screen flex-col items-end overflow-hidden"
    >
      <div className="flex flex-col flex-1 w-full overflow-hidden">
        <div
          className={cx(
            ui.open
              ? 'animate-in fade-in-0 zoom-in-95 flex'
              : 'animate-out fade-out-0 zoom-out-95 hidden',
            'slide-in-from-bottom-2 flex-1 flex-col z-10 overflow-hidden'
          )}
        >
          <Suspense fallback={<Layout header="Carregando..." />}>
            <Outlet context={{ group }} />
          </Suspense>
        </div>
      </div>
      <div
        className="flex-none pr-5 pb-5 pt-4"
        onClick={() => {
          setUI({ open: !ui.open });
        }}
      >
        <Indicator actor={group}>
          <Avatar size="lg" actor={group} className="cursor-pointer" />
        </Indicator>
      </div>
    </PageSettings>
  );
}
