import * as z from '@exact-magic/validation';
import { User } from './user.base';

export const UserRegister = User.pick({
  email: true,
  name: true,
}).extend({
  password: z.password(),
});
export type UserRegister = z.input<typeof UserRegister>;
