import { Suspense, forwardRef, useCallback, useMemo, useState } from 'react';
import { useCompany, useUser } from '@/hooks/auth';
import { usePrices } from '@/hooks/prices';
import { useTaxes } from '@/hooks/taxes';
import { trpc } from '@/trpc';
import { countries } from '@magicwrite/data/countries';
import { useTranslation } from '@magicwrite/i18n';
import {
  UpdateTaxes,
  UpdateTaxesAddress,
  UpdateTaxesGeneral,
} from '@magicwrite/schemas/taxes';
import { Demodal, useModal } from 'demodal';

import {
  Field,
  Form,
  SimpleField,
  Submit,
  useFormContext,
} from '@exact-magic/form';
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  User01Icon,
  Users01Icon,
} from '@exact-magic/icons';
import type { WithChildren } from '@exact-magic/react-utils';
import {
  Button,
  CardHeader,
  Content,
  Dialog,
  InputMask,
  Radio,
  Select,
  Separator,
  Wizard,
} from '@exact-magic/ui';

interface AddressFieldsProps {
  country: string;
}

const AddressFields = ({ country }: AddressFieldsProps) => {
  const { t } = useTranslation();
  const states = useMemo(
    () => countries.find((c) => c.alpha2Code === country)?.states || [],
    [country]
  );
  return (
    <>
      {country === 'BR' ? (
        <Field
          as={InputMask}
          mask="00000-000"
          name="zip"
          label="CEP"
          placeholder="00000-000"
          autoFocus
        />
      ) : (
        <Field name="zip" label={t('Código Postal')} autoFocus />
      )}
      <Field as={Select} name="state" label={t('Estado')}>
        {states?.map((state) => (
          <Select.Item key={`state-${state}`} value={state}>
            {state}
          </Select.Item>
        ))}
      </Field>
      <Field name="city" label={t('Cidade')} />
      <Field name="line1" label={t('Logradouro')} />
      <Field name="line2" label={t('Bairro')} />
    </>
  );
};

const GeneralFields = () => {
  const { t } = useTranslation();
  const {
    values: { type, country },
  } = useFormContext();
  return (
    <>
      <SimpleField as={Radio} name="type" className="grid grid-cols-2">
        <Radio.Item
          icon={Users01Icon}
          value="company"
          description={t('Contratei para minha empresa')}
        >
          {t('Pessoa Jurídica')}
        </Radio.Item>
        <Radio.Item
          icon={User01Icon}
          value="individual"
          description={t('Contratei para uso pessoal')}
        >
          {t('Pessoa Física')}
        </Radio.Item>
      </SimpleField>
      <Field as={Select} label={t('País')} name="country">
        {countries.map((country) => {
          return (
            <Select.Item key={country.alpha2Code} value={country.alpha2Code}>
              {country.name}
            </Select.Item>
          );
        })}
      </Field>

      {country === 'BR' ? (
        type === 'company' ? (
          <Field
            as={InputMask}
            name="document"
            mask="00.000.000/0000-00"
            label="CNPJ"
            placeholder="00.000.000/0000-00"
            autoFocus
          />
        ) : (
          <Field
            as={InputMask}
            name="document"
            mask="000.000.000-00"
            label="CPF"
            placeholder="999.999.999-99"
            autoFocus
          />
        )
      ) : (
        <Field name="document" label={t('Identificação Fiscal')} autoFocus />
      )}

      <Field name="name" label={t('Razão Social')} />
      <Field name="email" label={t('Email')} />
    </>
  );
};

const Actions = ({ children }: WithChildren) => {
  return <div className="gap-3 flex justify-end">{children}</div>;
};

interface TaxesFormProps {
  onSuccess: () => void;
}

const TaxesForm = ({ onSuccess }: TaxesFormProps) => {
  const { t } = useTranslation();
  const { country } = usePrices();
  const current = useTaxes();
  const company = useCompany();
  const user = useUser();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(
    current ||
      ({
        type: 'company',
        country,
        name: company.name,
        email: user.email,
      } as UpdateTaxes)
  );
  const utils = trpc.useContext();

  const update = trpc.taxes.update.useMutation({
    onSuccess: async () => {
      await utils.taxes.current.invalidate();
      close();
    },
  });

  const previous = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  const next = useCallback(() => {
    setStep((step) => step + 1);
  }, [setStep]);

  return (
    <Wizard value={step}>
      <Wizard.Step
        title={t('Identificação')}
        subtitle={t('Dados do comprador')}
      >
        <Form
          validationSchema={UpdateTaxesGeneral}
          initialValues={data}
          onSubmit={(data) => {
            setData((previous) => ({ ...previous, ...data }));
            next();
          }}
        >
          <Content>
            <GeneralFields />
            <Actions>
              <Submit
                trailingIcon={ArrowNarrowRightIcon}
                className="justify-end"
              >
                {t('Próximo')}
              </Submit>
            </Actions>
          </Content>
        </Form>
      </Wizard.Step>
      <Wizard.Step
        title={t('Endereço de cobrança')}
        subtitle={t('Dados do comprador')}
      >
        <Form
          validationSchema={UpdateTaxesAddress}
          initialValues={data}
          onSubmit={async (address) => {
            const taxes = { ...data, ...address };
            await update.mutateAsync(taxes);
            onSuccess();
          }}
        >
          <Content>
            <AddressFields country={data.country} />
            <Actions>
              <Button icon={ArrowNarrowLeftIcon} onClick={previous}>
                {t('Voltar')}
              </Button>
              <Submit
                trailingIcon={ArrowNarrowRightIcon}
                className="justify-end"
              >
                {t('Enviar')}
              </Submit>
            </Actions>
          </Content>
        </Form>
      </Wizard.Step>
    </Wizard>
  );
};

export interface TaxesDialogProps {
  editing?: boolean;
}

export const TaxesDialog = Demodal.create(
  ({ editing = false }: TaxesDialogProps) => {
    const { t } = useTranslation();
    const modal = useModal();

    const close = useCallback(() => {
      modal.resolve(null);
      modal.close();
    }, [editing, modal]);

    const finishMessage = () =>
      alert(t('Por favor, preencha os dados solicitados antes de prosseguir.'));

    return (
      <Dialog
        open={modal.isOpen}
        onOpenChange={
          modal.isOpen ? (editing ? close : finishMessage) : modal.open
        }
      >
        <Dialog.Content className="sm:max-w-screen-md max-h-[95vh] overflow-y-auto">
          <CardHeader
            title={t('Identificação Fiscal')}
            subtitle={
              editing
                ? t('Editar dados para emissão de notas fiscais')
                : t(
                    'Cartão validado com sucesso. Agora, informe os dados para emissão de notas fiscais.'
                  )
            }
          />
          <Separator />
          <Suspense>
            <TaxesForm
              onSuccess={() => {
                close();
              }}
            />
          </Suspense>
        </Dialog.Content>
      </Dialog>
    );
  }
);

export const taxes = (props?: TaxesDialogProps) =>
  Demodal.open(TaxesDialog, props);
