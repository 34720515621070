import { useAuth } from '@/auth';
import { taxes } from '@/settings/taxes';
import { trpc } from '@/trpc';

import { useSubscription } from './subscription';

export const useTaxes = () => {
  const auth = useAuth();
  const subscription = useSubscription();
  const { data } = trpc.taxes.current.useQuery(void 0, {
    enabled:
      auth.status === 'authenticated' &&
      auth.data.role === 'admin' &&
      !!subscription,
    onSuccess: (data) => {
      if (!data && subscription?.status !== 'trialing') taxes();
    },
  });
  return data;
};
