import { PlanTier } from '@magicwrite/data/plans';
import { useQuery } from '@tanstack/react-query';

export type Price = {
  priceID: string;
  currency: string;
  amount: number;
};

export type Prices = { country: string; prices: Record<PlanTier, Price> };

export const usePrices = () => {
  const { data } = useQuery({
    queryKey: ['prices'],
    queryFn: async () => {
      const response = await fetch(`/api/prices`);
      const prices: Prices = await response.json();
      return prices;
    },
    suspense: true,
  });
  return data!;
};

export const usePrice = (tier: PlanTier) => {
  const { prices } = usePrices();
  return prices[tier];
};
