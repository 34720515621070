import { forwardRef } from 'react';

import type { ExactComponent } from '@exact-magic/react-utils';
import {
  Avatar as BaseAvatar,
  AvatarProps as BaseAvatarProps,
  cx,
} from '@exact-magic/ui';
import type { Actor } from './types';

export interface AvatarProps extends Omit<BaseAvatarProps, 'src' | 'name'> {
  actor: Actor;
  ring?: boolean;
  className?: string;
}

export const Avatar = forwardRef(
  ({ actor, ring = false, size, className, ...other }, ref) => (
    <div className={cx(ring && 'p-1 rounded-full bg-white')}>
      <div className="relative">
        <BaseAvatar
          {...other}
          ref={ref}
          className={className}
          size={size}
          src={actor.avatar?.url}
          name={actor.name}
        />
      </div>
    </div>
  )
) as ExactComponent<'div', AvatarProps>;
