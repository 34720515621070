import type { Type } from '@magicwrite/core/user';

import * as z from '@exact-magic/validation';
import { file, implement, timestamps } from '../schema';

export const ID = z.id('user');
export type ID = z.infer<typeof ID>;

export const User = implement<Type>().with({
  userID: ID,
  name: z.string(),
  email: z.email(),
  picture: file().optional(),
  ...timestamps,
});
