import { useMemo } from 'react';
import type * as Agent from '@magicwrite/schemas/agent';
import type * as Group from '@magicwrite/schemas/agent-group';
import type * as Company from '@magicwrite/schemas/company';
import { useParams } from 'react-router-dom';

export const useWidgetParams = () => {
  const { agentID, companyID, groupID } = useParams<{
    groupID: Group.ID;
    agentID: Agent.ID;
    companyID: Company.ID;
  }>();
  return useMemo(
    () => ({ agentID: agentID!, companyID: companyID!, groupID: groupID! }),
    [agentID, companyID, groupID]
  );
};
