import type { Router } from '@magicwrite/functions/api/router';
import { httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

import { Auth } from './auth';

const url = `${import.meta.env.VITE_API_URL}/trpc`;

const headers = () => ({
  Authorization: `Bearer ${Auth.getToken()}`,
});

export const trpc = createTRPCReact<Router, unknown, 'ExperimentalSuspense'>();

export const TRPCProvider = trpc.Provider;

export const trpcClient = trpc.createClient({
  links: [
    loggerLink(),
    httpBatchLink({
      url,
      headers,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          // credentials: 'include',
        });
      },
    }),
  ],
});

export type RouterInput = inferRouterInputs<Router>;
export type RouterOutput = inferRouterOutputs<Router>;
