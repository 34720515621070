import { createBrowserRouter } from 'react-router-dom';

import { App } from './app';

export const router = createBrowserRouter([
  {
    path: '/:companyID/:groupID',
    element: <App />,
    children: [
      {
        index: true,
        lazy: () => import('./agents'),
      },
      {
        path: ':agentID',
        lazy: () => import('./agent'),
      },
    ],
  },
]);
