import { useAuth } from '@/auth';
import { usePrice } from '@/hooks/prices';
import { trpc } from '@/trpc';

export const useSubscription = () => {
  const auth = useAuth();
  const { data } = trpc.subscriptions.current.useQuery(void 0, {
    enabled: auth.status === 'authenticated' && !!auth.data.companyID,
  });
  return data;
};

export const useSubscribe = () => {
  const { priceID } = usePrice('content');
  const subscribe = trpc.subscriptions.subscribe.useMutation({
    onSuccess: async (url) => {
      window.location.href = url;
    },
  });
  return {
    subscribe: async () =>
      subscribe.mutateAsync({
        priceID,
        referral: window.referral || undefined,
      }),
    loading: subscribe.isLoading,
  };
};

export const useSetupPayment = () => {
  const setup = trpc.subscriptions.paymentMethod.useMutation({
    onSuccess: async (url) => {
      window.location.href = url;
    },
  });
  return setup;
};
