import * as z from '@exact-magic/validation';

type Implements<Model> = {
  [key in keyof Model]-?: undefined extends Model[key]
    ? null extends Model[key]
      ? z.ZodNullableType<z.ZodOptionalType<z.ZodType<Model[key]>>>
      : z.ZodOptionalType<z.ZodType<Model[key]>>
    : null extends Model[key]
    ? z.ZodNullableType<z.ZodType<Model[key]>>
    :
        | z.ZodType<Model[key]>
        | z.ZodEffects<z.ZodType<Model[key]>, Model[key], any>
        | z.ZodDefault<z.ZodType<Model[key]>>;
};

export function implement<Model = never>() {
  return {
    with: <
      Schema extends Implements<Model> & {
        [unknownKey in Exclude<keyof Schema, keyof Model>]: never;
      }
    >(
      schema: Schema
    ) => z.object(schema),
  };
}

export const timestamps = {
  createdAt: z.number(),
  updatedAt: z.number(),
};

export const audit = {
  createdBy: z.id('user'),
  updatedBy: z.id('user'),
};

export const tenant = {
  companyID: z.id('co'),
};

export const file = () =>
  z.object({
    url: z.url(),
    title: z.string().optional(),
    size: z.number().optional(),
    key: z.string().optional(),
    ext: z.string().optional(),
    img: z.string().optional(),
    description: z.string().optional(),
  });

export type MagicFile = z.infer<ReturnType<typeof file>>;
