import formatRelative from 'date-fns/formatRelative';
import ptBR from 'date-fns/locale/pt-BR';

const formatRelativeLocale = {
  lastWeek: "'Semana passada'",
  yesterday: "'Ontem'",
  today: "'Hoje'",
  tomorrow: "'Amanhã'",
  nextWeek: "'Próxima semana'",
  other: 'L LT',
};

const locale = {
  ...ptBR,
  formatRelative: (token: keyof typeof formatRelativeLocale) =>
    formatRelativeLocale[token],
};

export interface TimestampProps {
  className?: string;
  value?: Date | string | number;
}

export const Timestamp = ({ value, ...other }: TimestampProps) =>
  value ? (
    <time {...other}>
      {formatRelative(new Date(value), new Date(), { locale })}
    </time>
  ) : null;
