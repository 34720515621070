import TagManager from 'react-gtm-module';

export * as events from './index';

export const initialize = () => {
  TagManager.initialize({
    gtmId: import.meta.env.VITE_GTM_ID!,
    auth: import.meta.env.VITE_GTM_AUTH!,
    preview: import.meta.env.VITE_GTM_PREVIEW!,
  });
};

export const send = <T extends object>(event: string, data: T) => {
  TagManager.dataLayer({
    dataLayer: { event, ...data },
  });
};
