import { useEffect, useRef, useState, type CSSProperties } from 'react';
import type { PublicAgent } from '@magicwrite/schemas/agent';
import type { PublicAgentGroup } from '@magicwrite/schemas/agent-group';
import Color from 'color';

import { WithChildren } from '@exact-magic/react-utils';
import { cx } from '@exact-magic/ui';
import type { Actor } from './types';

const getColors = (actor: Actor) => {
  const base = Color(actor.color || '#fd2199');
  return {
    '--color-primary': base.hex(),
    '--color-primary-25': base.lighten(1).hex(),
    '--color-primary-50': base.lighten(0.95).hex(),
    '--color-primary-100': base.lighten(0.5).hex(),
    '--color-primary-200': base.lighten(0.4).hex(),
    '--color-primary-300': base.lighten(0.3).hex(),
    '--color-primary-400': base.lighten(0.2).hex(),
    '--color-primary-500': base.lighten(0.1).hex(),
    '--color-primary-600': base.hex(),
    '--color-primary-700': base.darken(0.1).hex(),
    '--color-primary-800': base.darken(0.2).hex(),
    '--color-primary-900': base.darken(0.3).hex(),
  } as CSSProperties;
};

export const useColors = (actor: Actor): CSSProperties => {
  const [colors, setColors] = useState<CSSProperties>({});

  useEffect(() => {
    const color = getColors(actor);
    setColors(color);
  }, [actor]);

  return colors;
};

export const usePageSettings = (actor: Actor): void => {
  // get link
  const faviconNode = useRef(document.querySelector('link[rel="icon"]'));

  // draw a new favicon and replace `link` tag
  const replaceFavicon = (img?: string) => {
    // do not set favicon if agent doesn't have an avatar
    if (!img) return;

    // create a new favicon tag
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = img;

    // remove the old favicon from the document
    if (faviconNode.current) document.head.removeChild(faviconNode.current);

    document.head.appendChild(link);
    faviconNode.current = link;
  };

  const replaceTitle = (
    name: string = 'Agente',
    description: string = 'Powered by Magic Write'
  ) => {
    document.title = `${name} | ${description}`;
  };

  useEffect(() => {
    replaceFavicon(actor.avatar?.url);
    replaceTitle(actor.name, actor.description);
  }, [actor]);
};

export const PageSettings = ({
  children,
  agent,
  group,
  className,
}: WithChildren<{
  agent?: PublicAgent;
  group?: PublicAgentGroup;
  className?: string;
}>) => {
  const actor = (agent || group)!;

  const colors = useColors(actor);
  usePageSettings(actor);

  return (
    <div className={cx('flex min-h-screen', className)} style={colors}>
      {children}
    </div>
  );
};
