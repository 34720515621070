import { useAuth } from '@/auth';
import { trpc } from '@/trpc';

export function useUser() {
  const { data } = trpc.users.current.useQuery();
  if (!data)
    throw new Error('useUser must be used inside an authenticated route');
  return data!;
}

export function useRole() {
  const auth = useAuth();
  if (auth.status !== 'authenticated' || !auth.data.companyID)
    throw new Error('useRole must be used inside an authenticated route');
  return auth.data.role;
}

export function useCompany() {
  const auth = useAuth();
  if (auth.status !== 'authenticated' || !auth.data.companyID)
    throw new Error('useCompany must be used inside an authenticated route');
  const { data } = trpc.companies.current.useQuery();
  return data!;
}
