import * as z from '@exact-magic/validation';
import { Company } from './company.base';

export const NewCompany = Company.pick({
  name: true,
  site: true,
  description: true,
}).extend({
  campaignID: z.string().optional(),
});
