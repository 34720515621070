import type { Type } from '@magicwrite/core/taxes';

import * as z from '@exact-magic/validation';
import * as Company from '../company';
import { implement, timestamps } from '../schema';

export const Taxes = implement<Type>().with({
  type: z.enum(['company', 'individual']).default('company'),
  name: z.string(),
  email: z.string(),
  country: z.string(),
  document: z.string(),
  zip: z.string(),
  state: z.string(),
  city: z.string(),
  line1: z.string(),
  line2: z.string().optional(),
  companyID: Company.ID,
  ...timestamps,
});
