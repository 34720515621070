import { PropsWithChildren } from 'react';

import { XCloseIcon } from '@exact-magic/icons';
import { Clickable } from '@exact-magic/ui';
import { useUI } from './ui';

const Close = () => {
  const { setUI } = useUI();
  return (
    <Clickable
      square
      icon={XCloseIcon}
      className="text-white hover:bg-cc-600 absolute right-3.5 top-3 xs:hidden"
      onClick={() => {
        setUI({ open: false });
      }}
    />
  );
};

export interface LayoutProps {
  header: React.ReactNode;
}

export const Layout = ({
  header,
  children,
}: PropsWithChildren<LayoutProps>) => {
  return (
    <div className="bg-white flex-1 flex flex-col overflow-hidden absolute inset-0 xs:relative xs:mx-5 xs:rounded-3xl xs:border">
      <div className="relative primary bg-cc px-16 h-16 font-semibold text-white flex-none flex items-center justify-center line-clamp-1">
        {header}
        <Close />
      </div>
      {children}
    </div>
  );
};
